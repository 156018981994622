<template>
  <div class="relative full-width full-height">
    <div
      class="absolute btn-prev-next"
      v-if="
        (isGroup === '1' ||
          isCollect === '1' ||
          isPatrol === '1') &&
          !autoPlay
      "
    >
      <a-button
        type="primary"
        @click="prevHandle"
        class="m-r-sm"
        :disabled="getCurrent === 0"
        >上一页</a-button
      >
      <a-button
        type="primary"
        @click="nextHandle"
        :disabled="getCurrent >= getPages - 1"
        >下一页</a-button
      >
    </div>
    <div class="screen">
      <div class="absolute r20 t0 zIndex">
        <a-button
          type="primary"
          class="m-r-sm"
          v-if="
            isGroup === '1' ||
              isCollect === '1' ||
              isPatrol === '1'
          "
          @click="togglePlayHandle"
          >{{ getPlayText }}</a-button
        >
        <a-button
          type="primary"
          class="m-r-sm"
          v-if="false"
          @click="toggleResolution"
          >{{ getBtnTit }}</a-button
        >
        <a-button
          type="primary"
          class="m-r-sm"
          @click="fullScreenHandle"
          ><a-icon type="desktop"
        /></a-button>
      </div>

      <div
        class="single-screen  full-height full-width"
        v-if="modeType === 1"
      >
        <split-screen
          ref="splitScreenFull"
          :size="1"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
      <div class="full-height" v-else-if="modeType === 4">
        <split-screen
          ref="splitScreenFull"
          :size="4"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
      <div class="full-height" v-else-if="modeType === 6">
        <split-screen
          ref="splitScreenFull"
          :size="6"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
      <div class="full-height" v-else-if="modeType === 9">
        <split-screen
          ref="splitScreenFull"
          :size="9"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
      <div class="full-height" v-else-if="modeType === 12">
        <split-screen
          ref="splitScreenFull"
          :size="12"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
      <div class="full-height" v-else-if="modeType === 16">
        <split-screen
          ref="splitScreenFull"
          :size="16"
          :screenMode="screen"
          @deleteCamera="(i) => delete cameraList[i]"
          @updateCameraList="(cls) => (cameraList = cls)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SplitScreen from '@/components/SplitScreen'
import {
  getStorage,
  clearStorage,
  setFlagArr
} from '@/tools'

let beforeunloadHandler

export default {
  name: 'Screen',
  props: {
    modeType: Number,
    screen: {
      type: Number,
      default: 0
    },
    winIndex: {
      type: [Number, String],
      default: -1
    },
    isPatrol: {
      type: String,
      default: '0'
    },
    user: {
      type: String,
      default: ''
    },
    isGroup: {
      type: String,
      default: '0'
    },
    isCollect: {
      type: String,
      default: '0'
    }
  },

  components: {
    SplitScreen
  },

  data() {
    return {
      cameraList: [],
      previousCameraList: [],
      current: 0,
      fullScreen: false,
      pages: 0,
      resolutionValue: 0,
      collectCurIndex: 0,
      groupCurIndex: 0,
      patrolCurIndex: 0,
      autoPlay: true,
      prefix: 'group'
    }
  },

  created() {
    beforeunloadHandler = this.beforeunloadHandler
    window.addEventListener(
      'beforeunload',
      beforeunloadHandler
    )
  },

  mounted() {
    // console.error('sxy--------------', this.isPatrol);
    if (this.isPatrol === '1') {
      let patrolCameral =
        getStorage('patrolCamera') ||
        JSON.parse(sessionStorage.getItem('patrolCamera'))
      let userKey = this.user
      if (patrolCameral) {
        // 仅当前页面存留
        sessionStorage.setItem(
          'patrolCamera',
          JSON.stringify(patrolCameral)
        )

        /* let patrolCameraInfo = patrolCameral[userKey];
        let videoType = patrolCameraInfo.highDefinition;
        // console.error('pppp', patrolCameraInfo);
        let cameraList = patrolCameraInfo.cameraInfo;
        this.cameraList = cameraList.slice().map((item) => {
          item.videoType = videoType;
          return item;
        });
        this.pageSize = patrolCameraInfo.cameraPatrolFrame;
        this.pages = Math.ceil(this.cameraList.length / this.pageSize);
        let times = patrolCameraInfo.cameraPatrolFrequencyInfo;
        this.seconds =
          times === '30s'
            ? 30 * 1000
            : times === '1min'
            ? 1 * 60 * 1000
            : 2 * 60 * 1000;

        this.play(); */
        this.prefix = 'patrol'
        this.initPatrolInfo(patrolCameral, userKey)
      } else {
        this.$message.error(
          '当前摄像机拉流地址失效，请关闭窗口，重新打开'
        )
      }
    } else if (this.isGroup === '1') {
      /*  this.cameraList = getStorage('groupCamera') || [];
      this.pages = Math.ceil(this.cameraList.length / this.modeType);
      this.current = 0;
      this.groupPlay(); */

      this.prefix = 'group'
      this.initInfo('group')
    } else if (this.isCollect === '1') {
      /* let collectObj = getStorage('collectCamera') || {};
      if(Object.prototype.hasOwnProperty.call(collectObj, 'interval')) {
        this.collCameraList = collectObj['arr'];
        this.collectInterval = collectObj['interval'];
        this.collPages = Math.ceil(this.collCameraList.length / this.modeType);
        // this.current = 0;
        // this.groupPlay();
        this.collCurIndex = 0;
        this.autoPlayCollect();
      } */
      this.prefix = 'collect'
      this.initInfo('collect')
    } else {
      this.cameraList =
        getStorage('cameraList')[this.winIndex] || []
      console.log('haha', this.cameraList)
      // this.cameraList = this.cameraList.filter((item) => !!item);
       this.cameraList.map((it, i) => {
        it.locationSign = `${it.cameraNum}-${i}`
      });
      this.$refs.splitScreenFull.setCameraList(
        this.cameraList
      )
    }
  },
  computed: {
    getBtnTit() {
      return `${{
        0: '标清',
        1: '高清',
        2: '原画'
      }[this.resolutionValue] || ''}轮巡`
    },
    getPlayText() {
      return `${this.autoPlay ? '暂停' : '自动轮巡'}`
    },
    getCurrent() {
      // return this.isCollect === '1'? this.collCurIndex : 0;
      return this[`${this.prefix}CurIndex`]
    },
    getPages() {
      // return this.isCollect === '1'? this.collPages : 0;
      return this[`${this.prefix}Pages`]
    }
  },
  watch: {},
  methods: {
    initPatrolInfo(patrolCameral, userKey) {
      let patrolCameraInfo = patrolCameral[userKey]
      // let videoType = patrolCameraInfo.highDefinition
      console.log(
        'Screen.vue patrolCameraInfo',
        JSON.parse(JSON.stringify(patrolCameraInfo))
      )
      this[`${this.prefix}CameraList`] =
        patrolCameraInfo.cameraInfo

      // 保存cameraNum列表
      sessionStorage.setItem(
        'cameraNums',
        JSON.stringify(
          patrolCameraInfo.cameraInfo.map(
            (e) => e.cameraNum
          )
        )
      )

      this[`${this.prefix}CameraList`] = this[
        `${this.prefix}CameraList`
      ]
        .slice()
        .map((item) => {
          return item
        })
      this[`${this.prefix}Pages`] = Math.ceil(
        this[`${this.prefix}CameraList`].length /
          this.modeType
      )
      let times = patrolCameraInfo.cameraPatrolFrequencyInfo
      this[`${this.prefix}Interval`] =
        times == '15s'
          ? 15
          : times === '30s'
          ? 30
          : times === '1min'
          ? 1 * 60
          : 2 * 60
      this[`${this.prefix}CurIndex`] = 0
      this.autoPlayCollect()
    },
    initInfo(prefix) {
      let collectObj = getStorage(`${prefix}Camera`) || {}
      if (
        Object.prototype.hasOwnProperty.call(
          collectObj,
          'interval'
        )
      ) {
        this[`${prefix}CameraList`] = collectObj['arr'].map(
          (item) => {
            // item.videoType = ''

            return item
          }
        )
        this[`${prefix}Interval`] = collectObj['interval']
        this[`${prefix}Pages`] = Math.ceil(
          this[`${prefix}CameraList`].length / this.modeType
        )
        // this.current = 0;
        // this.groupPlay();
        this[`${prefix}CurIndex`] = 0
        this.autoPlayCollect()
      }
    },
    togglePlayHandle() {
      this.autoPlay = !this.autoPlay
      /* if(this.isCollect === '1'){
        !this.autoPlay ? clearTimeout(this.collectTimer) : this.autoPlayCollect();
      } */
      !this.autoPlay
        ? clearTimeout(this[`${this.prefix}Timer`])
        : this.autoPlayCollect()
    },
    toggleResolution() {
      this.resolutionValue =
        this.resolutionValue === 0 ? 1 : 0
      /* this.cameraList = this.cameraList.map((item) => {
        item.videoType = this.resolutionValue;
        return item;
      });
      if (this.isGroup === '1' || this.isCollect === '1') {
        this.groupPlay();
      } else {
        this.$refs.splitScreenFull.setCameraList(this.cameraList);
      } */
      if (this.isPatrol !== '1') {
        if (
          this.isCollect === '1' ||
          this.isGroup === '1'
        ) {
          /* this.collCameraList = this.collCameraList.map((item) => {
            item.videoType = this.resolutionValue;
            return item;
          });
          this.autoPlay = false;
          clearTimeout(this.collectTimer);
          this.playCollectCurrent(); 封装*/
          this.handleRev()
        } else {
          this.cameraList = this.cameraList.map((item) => {
            if (item) {
              // item.videoType = this.resolutionValue
              console.log(item.videoType, '清晰度')
              return item
            }
          })
          console.log(this.cameraList, '切换摄像机组')
           this.cameraList.map((it, i) => {
          it.locationSign = `${it.cameraNum}-${i}`
         });
          this.$refs.splitScreenFull.setCameraList(
            this.cameraList
          )
        }
      }
    },
    handleRev() {
      this[`${this.prefix}CameraList`] = this[
        `${this.prefix}CameraList`
      ].map((item) => {
        return item
      })
      this.autoPlay = false
      clearTimeout(this[`${this.prefix}Timer`])
      this.playCollectCurrent()
    },
    stopMulStream() {
      this.$refs.splitScreenFull.stopStreamMulHandle()
    },
    stopPreviousStream(singleCamera) {
      let flag = true
      if (singleCamera.videoType !== 1) {
        flag = false
      }
      if (!flag) {
        return
      }
      this.$refs.splitScreenFull.stopStreamSingleHandle(
        singleCamera
      )
    },
    play() {
      // let vm = this;
      // this.current = this.current < this.pages ? this.current : this.pages;
      /* debugger; */
      //停止高清流
      /*  if (this.previousCameraList.length) {
        for (let i = 0; i < this.previousCameraList.length; i++) {
          this.stopPreviousStream(this.previousCameraList[i]);
        }
      } fixsxy实现效果并不好*/

      this.start = this.current * this.pageSize
      this.end =
        this.start + this.pageSize > this.cameraList.length
          ? this.cameraList.length
          : this.start + this.pageSize
      let cameraList = this.cameraList.slice(
        this.start,
        this.end
      )
      this.previousCameraList = cameraList
      this.current++
      if (this.current >= this.pages) {
        this.current = 0
      }
      /*  console.error(
        'papa',
        this.current,
        this.pages,
        this.start,
        this.end,
        cameraList
      ); */

      /* let newCameraList = cameraList.slice().map((item) => {
        item.videoType = this.videoType;
        return item;
      }); */
      /*  console.log('papa', this.videoType, newCameraList); */
       cameraList.map((it, i) => {
        it.locationSign = `${it.cameraNum}-${i}`
      });
      this.$refs.splitScreenFull.setCameraList(
        cameraList,
        this.current - 1
      )
      if (this.modeType < this.cameraList.length) {
        this.timer = setTimeout(() => {
          this.play()
        }, this.seconds)
      }
    },
    autoPlayCollect() {
      this.playCollectCurrent()
      /* this.collectTimer = setTimeout(() => {
         this.collCurIndex = this.collCurIndex >= (this.collPages-1) ? 0 : ++this.collCurIndex;
        this.autoPlayCollect();
        // this.playCollectCurrent();
      },parseInt(this.collectInterval)*1000); */
      this[`${this.prefix}Timer`] = setTimeout(() => {
        this[`${this.prefix}CurIndex`] =
          this[`${this.prefix}CurIndex`] >=
          this[`${this.prefix}Pages`] - 1
            ? 0
            : ++this[`${this.prefix}CurIndex`]
        this.autoPlayCollect()
        // this.playCollectCurrent();
      }, parseInt(this[`${this.prefix}Interval`]) * 1000)
    },
    playCollectCurrent() {
      // let start = this.collCurIndex * this.modeType;
      // let end = start + this.modeType > this.collCameraList.length ? this.collCameraList.length : start + this.modeType;
      // let cameraList = this.collCameraList.slice(start, end);
      // this.$refs.splitScreenFull.setCameraList(cameraList,this.collCurIndex);
      let start =
        this[`${this.prefix}CurIndex`] * this.modeType
      let end =
        start + this.modeType >
        this[`${this.prefix}CameraList`].length
          ? this[`${this.prefix}CameraList`].lengthi
          : start + this.modeType
      let cameraList = this[
        `${this.prefix}CameraList`
      ].slice(start, end)
      cameraList.map((it, i) => {
        it.locationSign = `${it.cameraNum}-${i}`
      });
      this.$refs.splitScreenFull.setCameraList(
        cameraList,
        this[`${this.prefix}CurIndex`]
      )
    },
    groupPlay() {
      /* if(this.cameraList.length<=this.winIndex){
        this.current = 0;
        this.$refs.splitScreenFull.setCameraList(this.cameraList)
      }else{

      } */
      this.start = this.current * this.modeType
      this.end =
        this.start + this.modeType > this.cameraList.length
          ? this.cameraList.length
          : this.start + this.modeType

      let cameraList = this.cameraList.slice(
        this.start,
        this.end
      )
      console.log('haha', this.cameraList, cameraList)
       cameraList.map((it, i) => {
        it.locationSign = `${it.cameraNum}-${i}`
      });
      this.$refs.splitScreenFull.setCameraList(
        cameraList,
        this.current
      )
      /* this.pages = Math.ceil(this.cameraList.length / this.winIndex);
      if (this.current >= this.pages) {
        this.current = 0;
      } */
    },
    fullScreenHandle() {
      let element = document.documentElement
      let isFullscreen =
        document.fullScreenElement || //W3C
        document.msFullscreenElement || //IE
        document.mozFullScreenElement || //火狐
        document.webkitFullscreenElement || //谷歌
        false

      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.fullScreen = false
        //console.log('已还原！');
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }

        this.fullScreen = true
      }
      // 改变当前全屏状态
      // this.hasFullScreen = !this.hasFullScreen;
    },
    prevHandle() {
      /* this.current--;
      this.groupPlay(); */
      /* if(this.isCollect === '1') {
        this.collCurIndex--;
        this.playCollectCurrent();
      } */
      this[`${this.prefix}CurIndex`]--
      this.playCollectCurrent()
    },
    nextHandle() {
      /* this.current++;
      this.groupPlay(); */
      /* if(this.isCollect === '1') {
        this.collCurIndex++;
        this.playCollectCurrent();
      } */
      this[`${this.prefix}CurIndex`]++
      this.playCollectCurrent()
    },

    // 页面关闭处理
    beforeunloadHandler() {
      setFlagArr()
      //清空轮询预案视频拉流
      clearStorage('groupCamera')
      clearStorage('patrolCamera')
      clearStorage('collectCamera')

      // 接口停流所有
      const cameraNums = JSON.parse(
        sessionStorage.getItem('cameraNums')
      )

      this.$api.home
        .stopStream({
          cameraNums,
          streamType: -2
        })
        .then(() => {})
      this.$api.home
        .stopStream({
          cameraNums,
          streamType: -3
        })
        .then(() => {})

      // 移除事件
      window.removeEventListener(
        'beforeunload',
        beforeunloadHandler
      )
    }
  },

  destroyed() {
    setFlagArr()
    this.timer && clearTimeout(this.timer)
  }

  /*  destroyed() {
    clearTimeout(this.timer);
    clearStorage('cameraList');
    clearStorage('patrolCameral');
    this.$socket.onclose();
  }, */
}
</script>

<style scoped>
html,
body,
#app {
  padding: 0 !important;
}
.screen {
  width: 100%;
  height: 100%;
}
.bdl {
  border-left: 1px dashed rgba(28, 91, 178, 1);
}
.bdr {
  border-right: 1px dashed rgba(28, 91, 178, 1);
}
.bdb {
  border-bottom: 1px dashed rgba(28, 91, 178, 1);
}
.bdt {
  border-top: 1px dashed rgba(28, 91, 178, 1);
}
.ht50 {
  height: 50%;
}
.r20 {
  right: 20px;
}
.r40 {
  right: 40px;
}
.btn-prev-next {
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
.zIndex {
  z-index: 9999;
}
</style>
